import create from 'zustand';


const useLiningChartsState = create((set,get) => ({
  setState : (newstate ) => set(state => newstate),

  Oefen: null,
  setOefen: (wert) => set(state => ({ Oefen: wert })),
  selectedOfen: null,
  setSelectedOfen: (wert) => set(state => ({ selectedOfen: wert })),

  OfenName: "",
  setOfenName: (wert) => set(state => ({ OfenName: wert })),
  OfenLaenge: "",
  setOfenLaenge: (wert) => set(state => ({ OfenLaenge: wert })),
  OfenKommentar: "",
  setOfenKommentar: (wert) => set(state => ({ OfenKommentar: wert })),

  OfenCommand: "",
  setOfenCommand: (wert) => set(state => ({ OfenCommand: wert })),
  OfenResponse: null,
  setOfenResponse: (wert) => set(state => ({ OfenResponse: wert })),
  OfenDialogue: false,
  setOfenDialogue: (wert) => set(state => ({ OfenDialogue: wert })),
  OfenLoading: false,
  setOfenLoading: (wert) => set(state => ({ OfenLoading: wert })),
  OfenNoConnection: false,
  setOfenNoConnection: (wert) => set(state => ({ OfenNoConnection: wert })),
  NewOvenCreation: false,
  setNewOvenCreation: (wert) => set(state => ({ NewOvenCreation: wert })),

  selectedPosition: null,
  setSelectedPosition: (wert) => set(state => ({ selectedPosition: wert })),

  positionMode: "none",
  setPositionMode: (wert) => set(state => ({ positionMode: wert })),

  PositionName: "",
  setPositionName: (wert) => set(state => ({ PositionName: wert })),
  PositionKommentar: "",
  setPositionKommentar: (wert) => set(state => ({ PositionKommentar: wert })),
  PositionDurchmesser: "",
  setPositionDurchmesser: (wert) => set(state => ({ PositionDurchmesser: wert })),
  PositionOfenmantelverschmutzung: "none",
  setPositionOfenmantelverschmutzung: (wert) => set(state => ({ PositionOfenmantelverschmutzung: wert })),
  PositionOfenmantelVerformung: "none",
  setPositionOfenmantelVerformung: (wert) => set(state => ({ PositionOfenmantelVerformung: wert })),

  PositionAuskleidungsmaterial: "stein",
  setPositionAuskleidungsmaterial: (wert) => set(state => ({ PositionAuskleidungsmaterial: wert })),
  PositionAuskleidungsmethode: "einschicht",
  setPositionAuskleidungsmethode: (wert) => set(state => ({ PositionAuskleidungsmethode: wert })),

  PositionArbeitsfutterHersteller: "",
  setPositionArbeitsfutterHersteller: (wert) => set(state => ({ PositionArbeitsfutterHersteller: wert })),
  PositionArbeitsfutterProdukt: "",
  setPositionArbeitsfutterProdukt: (wert) => set(state => ({ PositionArbeitsfutterProdukt: wert })),
  PositionIsolierfutterHersteller: "",
  setPositionIsolierfutterHersteller: (wert) => set(state => ({ PositionIsolierfutterHersteller: wert })),
  PositionIsolierfutterProdukt: "",
  setPositionIsolierfutterProdukt: (wert) => set(state => ({ PositionIsolierfutterProdukt: wert })),
  PositionIsolierfutterDicke: "0",
  setPositionIsolierfutterDicke: (wert) => set(state => ({ PositionIsolierfutterDicke: wert })),
  PositionMoertelArbeitsfutter: "",
  setPositionMoertelArbeitsfutter: (wert) => set(state => ({ PositionMoertelArbeitsfutter: wert })),
  PositionMoertelIsolierfutter: "",
  setPositionMoertelIsolierfutter: (wert) => set(state => ({ PositionMoertelIsolierfutter: wert })),

  PositionAnkerLieferant: "",
  setPositionAnkerLieferant: (wert) => set(state => ({ PositionAnkerLieferant: wert })),
  PositionAnkerTyp: "",
  setPositionAnkerTyp: (wert) => set(state => ({ PositionAnkerTyp: wert })),
  PositionAnkerQualitaet: "",
  setPositionAnkerQualitaet: (wert) => set(state => ({ PositionAnkerQualitaet: wert })),

  PositionFormatTyp: "vdz",
  setPositionFormatTyp: (wert) => set(state => ({ PositionFormatTyp: wert })),

  PositionFormatEins: "",
  setPositionFormatEins: (wert) => set(state => ({ PositionFormatEins: wert })),
  PositionFormatZwei: "",
  setPositionFormatZwei: (wert) => set(state => ({ PositionFormatZwei: wert })),
  PositionPassSteinEins: "",
  setPositionPassSteinEins: (wert) => set(state => ({ PositionPassSteinEins: wert })),
  PositionPassSteinZwei: "",
  setPositionPassSteinZwei: (wert) => set(state => ({ PositionPassSteinZwei: wert })),
  PositionFormatEinsRatio: "0",
  setPositionFormatEinsRatio: (wert) => set(state => ({ PositionFormatEinsRatio: wert })),
  PositionFormatZweiRatio: "0",
  setPositionFormatZweiRatio: (wert) => set(state => ({ PositionFormatZweiRatio: wert })),

  PositionRingeVerband: "ringe",
  setPositionRingeVerband: (wert) => set(state => ({ PositionRingeVerband: wert })),
  PositionKnirschMoertel: "knirsch",
  setPositionKnirschMoertel: (wert) => set(state => ({ PositionKnirschMoertel: wert })),

  PositionHaltering: false,
  setPositionHaltering: (wert) => set(state => ({ PositionHaltering: wert })),
  PositionKonus: false,
  setPositionKonus: (wert) => set(state => ({ PositionKonus: wert })),
  PositionLifterzone: false,
  setPositionLifterzone: (wert) => set(state => ({ PositionLifterzone: wert })),
  PositionKettenzone: false,
  setPositionKettenzone: (wert) => set(state => ({ PositionKettenzone: wert })),
  PositionKammfutter: false,
  setPositionKammfutter: (wert) => set(state => ({ PositionKammfutter: wert })),
  PositionOpferbleche: false,
  setPositionOpferbleche: (wert) => set(state => ({ PositionOpferbleche: wert })),
  PositionDammring: false,
  setPositionDammring: (wert) => set(state => ({ PositionDammring: wert })),
  PositionSpiralmauerwerk: false,
  setPositionSpiralmauerwerk: (wert) => set(state => ({ PositionSpiralmauerwerk: wert })),

  PositionsHistorieStartposition: "0",
  setPositionsHistorieStartposition: (wert) => set(state => ({ PositionsHistorieStartposition: wert })),
  PositionsHistorieLaenge: "0",
  setPositionsHistorieLaenge: (wert) => set(state => ({ PositionsHistorieLaenge: wert })),
  PositionsHistorieStaerke: "0",
  setPositionsHistorieStaerke: (wert) => set(state => ({ PositionsHistorieStaerke: wert })),

  PositionLoading: false,
  setPositionLoading: (wert) => set(state => ({ PositionLoading: wert })),
  PositionInfo: null,
  setPositionInfo: (wert) => set(state => ({ PositionInfo: wert })),

  PositionCommand: "",
  setPositionCommand: (wert) => set(state => ({ PositionCommand: wert })),
  PositionResponse: null,
  setPositionResponse: (wert) => set(state => ({ PositionResponse: wert })),
  PositionDialogue: false,
  setPositionDialogue: (wert) => set(state => ({ PositionDialogue: wert })),
}));

export default useLiningChartsState;