import logo from '../logo.svg';
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";

import './Positions.css'
import useLiningChartsState from '../state/liningcharts';

import { APICreatePosition } from '../api/APICreatePosition';
import { APIDeletePosition } from '../api/APIDeletePosition';
import { APICreatePositionsHistorie } from '../api/APICreatePositionsHistorie'
import { APILoadPositionDetails } from '../api/APILoadPositionDetails'

import { useNavigate } from 'react-router';
import { useEffect, useState } from "react"

function Positions() {
  const history = useNavigate();

  const PositionName = useLiningChartsState(state => state.PositionName);
  const setPositionName = useLiningChartsState(state => state.setPositionName)
  const PositionKommentar = useLiningChartsState(state => state.PositionKommentar);
  const setPositionKommentar = useLiningChartsState(state => state.setPositionKommentar)
  const PositionDurchmesser = useLiningChartsState(state => state.PositionDurchmesser);
  const setPositionDurchmesser = useLiningChartsState(state => state.setPositionDurchmesser)

  const PositionOfenmantelverschmutzung = useLiningChartsState(state => state.PositionOfenmantelverschmutzung);
  const setPositionOfenmantelverschmutzung = useLiningChartsState(state => state.setPositionOfenmantelverschmutzung)
  const PositionOfenmantelVerformung = useLiningChartsState(state => state.PositionOfenmantelVerformung);
  const setPositionOfenmantelVerformung = useLiningChartsState(state => state.setPositionOfenmantelVerformung)
  const PositionAuskleidungsmaterial = useLiningChartsState(state => state.PositionAuskleidungsmaterial);
  const setPositionAuskleidungsmaterial = useLiningChartsState(state => state.setPositionAuskleidungsmaterial)
  const PositionAuskleidungsmethode = useLiningChartsState(state => state.PositionAuskleidungsmethode);
  const setPositionAuskleidungsmethode = useLiningChartsState(state => state.setPositionAuskleidungsmethode)

  const PositionArbeitsfutterHersteller = useLiningChartsState(state => state.PositionArbeitsfutterHersteller);
  const setPositionArbeitsfutterHersteller = useLiningChartsState(state => state.setPositionArbeitsfutterHersteller)
  const PositionArbeitsfutterProdukt = useLiningChartsState(state => state.PositionArbeitsfutterProdukt);
  const setPositionArbeitsfutterProdukt = useLiningChartsState(state => state.setPositionArbeitsfutterProdukt)
  const PositionIsolierfutterHersteller = useLiningChartsState(state => state.PositionIsolierfutterHersteller);
  const setPositionIsolierfutterHersteller = useLiningChartsState(state => state.setPositionIsolierfutterHersteller)
  const PositionIsolierfutterProdukt = useLiningChartsState(state => state.PositionIsolierfutterProdukt);
  const setPositionIsolierfutterProdukt = useLiningChartsState(state => state.setPositionIsolierfutterProdukt)
  const PositionIsolierfutterDicke = useLiningChartsState(state => state.PositionIsolierfutterDicke);
  const setPositionIsolierfutterDicke = useLiningChartsState(state => state.setPositionIsolierfutterDicke)
  const PositionMoertelArbeitsfutter = useLiningChartsState(state => state.PositionMoertelArbeitsfutter);
  const setPositionMoertelArbeitsfutter = useLiningChartsState(state => state.setPositionMoertelArbeitsfutter)
  const PositionMoertelIsolierfutter = useLiningChartsState(state => state.PositionMoertelIsolierfutter);
  const setPositionMoertelIsolierfutter = useLiningChartsState(state => state.setPositionMoertelIsolierfutter)

  const PositionAnkerLieferant = useLiningChartsState(state => state.PositionAnkerLieferant);
  const setPositionAnkerLieferant = useLiningChartsState(state => state.setPositionAnkerLieferant)
  const PositionAnkerTyp = useLiningChartsState(state => state.PositionAnkerTyp);
  const setPositionAnkerTyp = useLiningChartsState(state => state.setPositionAnkerTyp)
  const PositionAnkerQualitaet = useLiningChartsState(state => state.PositionAnkerQualitaet);
  const setPositionAnkerQualitaet = useLiningChartsState(state => state.setPositionAnkerQualitaet)

  const PositionFormatTyp = useLiningChartsState(state => state.PositionFormatTyp);
  const setPositionFormatTyp = useLiningChartsState(state => state.setPositionFormatTyp)

  const PositionFormatEins = useLiningChartsState(state => state.PositionFormatEins);
  const setPositionFormatEins = useLiningChartsState(state => state.setPositionFormatEins)
  const PositionFormatZwei = useLiningChartsState(state => state.PositionFormatZwei);
  const setPositionFormatZwei = useLiningChartsState(state => state.setPositionFormatZwei)
  const PositionPassSteinEins = useLiningChartsState(state => state.PositionPassSteinEins);
  const setPositionPassSteinEins = useLiningChartsState(state => state.setPositionPassSteinEins)
  const PositionPassSteinZwei = useLiningChartsState(state => state.PositionPassSteinZwei);
  const setPositionPassSteinZwei = useLiningChartsState(state => state.setPositionPassSteinZwei)
  const PositionFormatEinsRatio = useLiningChartsState(state => state.PositionFormatEinsRatio);
  const setPositionFormatEinsRatio = useLiningChartsState(state => state.setPositionFormatEinsRatio)
  const PositionFormatZweiRatio = useLiningChartsState(state => state.PositionFormatZweiRatio);
  const setPositionFormatZweiRatio = useLiningChartsState(state => state.setPositionFormatZweiRatio)

  const PositionRingeVerband = useLiningChartsState(state => state.PositionRingeVerband);
  const setPositionRingeVerband = useLiningChartsState(state => state.setPositionRingeVerband)
  const PositionKnirschMoertel = useLiningChartsState(state => state.PositionKnirschMoertel);
  const setPositionKnirschMoertel = useLiningChartsState(state => state.setPositionKnirschMoertel)

  const PositionHaltering = useLiningChartsState(state => state.PositionHaltering);
  const setPositionHaltering = useLiningChartsState(state => state.setPositionHaltering)
  const PositionKonus = useLiningChartsState(state => state.PositionKonus);
  const setPositionKonus = useLiningChartsState(state => state.setPositionKonus)
  const PositionLifterzone = useLiningChartsState(state => state.PositionLifterzone);
  const setPositionLifterzone = useLiningChartsState(state => state.setPositionLifterzone)
  const PositionKettenzone = useLiningChartsState(state => state.PositionKettenzone);
  const setPositionKettenzone = useLiningChartsState(state => state.setPositionKettenzone)
  const PositionKammfutter = useLiningChartsState(state => state.PositionKammfutter);
  const setPositionKammfutter = useLiningChartsState(state => state.setPositionKammfutter)
  const PositionOpferbleche = useLiningChartsState(state => state.PositionOpferbleche);
  const setPositionOpferbleche = useLiningChartsState(state => state.setPositionOpferbleche)
  const PositionDammring = useLiningChartsState(state => state.PositionDammring);
  const setPositionDammring = useLiningChartsState(state => state.setPositionDammring)
  const PositionSpiralmauerwerk = useLiningChartsState(state => state.PositionSpiralmauerwerk);
  const setPositionSpiralmauerwerk = useLiningChartsState(state => state.setPositionSpiralmauerwerk)


  const PositionsHistorieStartposition = useLiningChartsState(state => state.PositionsHistorieStartposition);
  const setPositionsHistorieStartposition = useLiningChartsState(state => state.setPositionsHistorieStartposition)
  const PositionsHistorieLaenge = useLiningChartsState(state => state.PositionsHistorieLaenge);
  const setPositionsHistorieLaenge = useLiningChartsState(state => state.setPositionsHistorieLaenge)
  const PositionsHistorieStaerke = useLiningChartsState(state => state.PositionsHistorieStaerke);
  const setPositionsHistorieStaerke = useLiningChartsState(state => state.setPositionsHistorieStaerke)

  const selectedOfen = useLiningChartsState(state => state.selectedOfen);

  const selectedPosition = useLiningChartsState(state => state.selectedPosition);
  const setSelectedPosition = useLiningChartsState(state => state.setSelectedPosition)

  const Oefen = useLiningChartsState(state => state.Oefen);

  const PositionLoading = useLiningChartsState(state => state.PositionLoading);
  const setPositionLoading = useLiningChartsState(state => state.setPositionLoading)
  const PositionInfo = useLiningChartsState(state => state.PositionInfo);
  const setPositionInfo = useLiningChartsState(state => state.setPositionInfo)

  const positionMode = useLiningChartsState(state => state.positionMode);
  const setPositionMode = useLiningChartsState(state => state.setPositionMode)

  const PositionCommand = useLiningChartsState(state => state.PositionCommand);
  const setPositionCommand = useLiningChartsState(state => state.setPositionCommand)
  const PositionResponse = useLiningChartsState(state => state.PositionResponse);
  const setPositionResponse = useLiningChartsState(state => state.setPositionResponse)
  const PositionDialogue = useLiningChartsState(state => state.PositionDialogue);
  const setPositionDialogue = useLiningChartsState(state => state.setPositionDialogue)

  useEffect(() => {
    if (Oefen == null) {
      history("/");
      return;
    }
    setSelectedPosition(null);
    UpdatePositionInfo();
    ClearFields();
  }, []);


  useEffect(() => {
    if (PositionResponse == null) {
      return;
    }
    if (PositionResponse == false) {
      setPositionCommand("");
      setPositionResponse(null);
      return;
    }
    switch (PositionCommand) {
      case "del":
        setPositionLoading(true);
        console.log("del");

        APIDeletePosition(PositionInfo[selectedPosition].id).then(() => {
          let array = PositionInfo;
          array.splice(selectedPosition, 1);
          setPositionInfo(array);
          setSelectedPosition(null);
          ClearFields();
          setPositionLoading(false);
          setPositionCommand("");
          setPositionResponse(null);
        });
        break;
      case "update":
        setPositionLoading(true);
        console.log("update");

        let start_posi;
        let lng;
        let strk;

        if (!isNaN(PositionsHistorieStartposition)) {
          start_posi = parseFloat(PositionsHistorieStartposition);
        }
        else {
          console.log("PositionsHistorieStartposition not a float");
          return;
        }
        if (!isNaN(PositionsHistorieLaenge)) {
          lng = parseFloat(PositionsHistorieLaenge);
        }
        else {
          console.log("PositionsHistorieLaenge not a float");
          return;
        }
        if (!isNaN(PositionsHistorieStaerke)) {
          strk = parseFloat(PositionsHistorieStaerke);
        }
        else {
          console.log("PositionsHistorieStaerke not a float");
          return;
        }
        setPositionLoading(true);
        APICreatePositionsHistorie(PositionInfo[selectedPosition].id, start_posi, lng, strk).then((res) => {
          PositionInfo[selectedPosition].historie[PositionInfo[selectedPosition].historie.length - 1].startposition = PositionsHistorieStartposition;
          PositionInfo[selectedPosition].historie[PositionInfo[selectedPosition].historie.length - 1].laenge = PositionsHistorieLaenge;
          PositionInfo[selectedPosition].historie[PositionInfo[selectedPosition].historie.length - 1].staerke = PositionsHistorieStaerke;

          ClearFields();

          PositionSelected(selectedPosition);

          setPositionLoading(false);
          setPositionCommand("");
          setPositionResponse(null);
        });

        break;
      case "create":
        setPositionLoading(true);
        console.log("create");
        let posi_durchmesser;
        let iso_dicke;
        let formatoneratio;
        let formattworatio;



        let n_start_posi;
        let n_lng;
        let n_strk;

        if (!isNaN(PositionDurchmesser)) {
          posi_durchmesser = parseFloat(PositionDurchmesser);
        }
        else {
          console.log("PositionDurchmesser not a float");
          return;
        }

        if (!isNaN(PositionIsolierfutterDicke)) {
          iso_dicke = parseFloat(PositionIsolierfutterDicke);
        }
        else {
          console.log("PositionIsolierfutterDicke not a float");
          return;
        }

        if (!isNaN(PositionsHistorieStartposition)) {
          n_start_posi = parseFloat(PositionsHistorieStartposition);
        }
        else {
          console.log("PositionsHistorieStartposition not a float");
          return;
        }
        if (!isNaN(PositionsHistorieLaenge)) {
          n_lng = parseFloat(PositionsHistorieLaenge);
        }
        else {
          console.log("PositionsHistorieLaenge not a float");
          return;
        }
        if (!isNaN(PositionsHistorieStaerke)) {
          n_strk = parseFloat(PositionsHistorieStaerke);
        }
        else {
          console.log("PositionsHistorieStaerke not a float");
          return;
        }

        if (!isNaN(PositionFormatEinsRatio)) {
          formatoneratio = Math.round(parseFloat(PositionFormatEinsRatio));
        }
        else {
          console.log("PositionFormatEinsRatio not a float");
          return;
        }
        if (!isNaN(PositionFormatZweiRatio)) {
          formattworatio = Math.round(parseFloat(PositionFormatZweiRatio));
        }
        else {
          console.log("PositionFormatZweiRatio not a float");
          return;
        }

        console.log("ONE!");
        APICreatePosition(Oefen[selectedOfen].id, PositionName, PositionKommentar, posi_durchmesser, PositionOfenmantelverschmutzung, PositionOfenmantelVerformung, PositionAuskleidungsmaterial, PositionAuskleidungsmethode, PositionArbeitsfutterHersteller, PositionArbeitsfutterProdukt,
          PositionIsolierfutterHersteller, PositionIsolierfutterProdukt, iso_dicke, PositionMoertelArbeitsfutter, PositionMoertelIsolierfutter, PositionAnkerLieferant, PositionAnkerTyp, PositionAnkerQualitaet, PositionFormatTyp, PositionFormatEins, PositionFormatZwei, PositionPassSteinEins, PositionPassSteinZwei,
          formatoneratio, formattworatio, PositionRingeVerband, PositionKnirschMoertel, PositionHaltering, PositionKonus, PositionLifterzone, PositionKettenzone, PositionKammfutter, PositionOpferbleche, PositionDammring, PositionSpiralmauerwerk, n_start_posi, n_lng, n_strk).then((response) => {

            console.log(response.id)

            if (response.status == "OK") {

              let PositionNew = { id: response.id };
              PositionNew.id = response.id;
              PositionNew.name = PositionName;
              PositionNew.kommentar = PositionKommentar;
              PositionNew.durchmesser = posi_durchmesser;

              PositionNew.ofenmantelVerschmutzung = PositionOfenmantelverschmutzung;
              PositionNew.ofenmantelVerformung = PositionOfenmantelVerformung;
              PositionNew.auskleidungsmaterial = PositionAuskleidungsmaterial;
              PositionNew.auskleidungsmethode = PositionAuskleidungsmethode;

              PositionNew.arbeitsfutterHersteller = PositionArbeitsfutterHersteller;
              PositionNew.arbeitsfutterProdukt = PositionArbeitsfutterProdukt;
              PositionNew.isolierfutterHersteller = PositionIsolierfutterHersteller;
              PositionNew.isolierfutterProdukt = PositionIsolierfutterProdukt;
              PositionNew.isolierfutterDicke = iso_dicke;
              PositionNew.moertelArbeitsfutter = PositionMoertelArbeitsfutter;
              PositionNew.moertelIsolierfutter = PositionMoertelIsolierfutter;

              PositionNew.ankerLieferant = PositionAnkerLieferant;
              PositionNew.ankerTyp = PositionAnkerTyp;
              PositionNew.ankerQualitaet = PositionAnkerQualitaet;
              PositionNew.formatTyp = PositionFormatTyp;

              PositionNew.formatEins = PositionFormatEins;
              PositionNew.formatZwei = PositionFormatZwei;
              PositionNew.passSteinEins = PositionPassSteinEins;
              PositionNew.passSteinZwei = PositionPassSteinZwei;
              PositionNew.formatEinsRatio = formatoneratio;
              PositionNew.formatZweiRatio = formattworatio;

              PositionNew.ringeVerband = PositionRingeVerband;
              PositionNew.knirschMoertel = PositionKnirschMoertel;

              PositionNew.haltering = PositionHaltering;
              PositionNew.konus = PositionKonus;
              PositionNew.lifterzone = PositionLifterzone;
              PositionNew.kettenzone = PositionKettenzone;
              PositionNew.kammfutter = PositionKammfutter;
              PositionNew.opferbleche = PositionOpferbleche;
              PositionNew.dammring = PositionDammring;
              PositionNew.spiralmauerwerk = PositionSpiralmauerwerk;



              PositionNew.historie = [{ id: "0", startposition: n_start_posi, laenge: n_lng, staerke: n_strk }]

              let temp_list = PositionInfo;
              temp_list.push(PositionNew);
              setPositionInfo(temp_list)
            }
            else {
              console.log("ERROR");
            }



            ClearFields();
            setSelectedPosition(null);
            setPositionLoading(false);
            setPositionCommand("");
            setPositionResponse(null);

          });

        break;
      default:
        console.log("default");
    }

  }, [PositionResponse]);


  function DialogueYes() {
    setPositionDialogue(false);
    setPositionResponse(true);
  }
  function DialogueNo() {
    setPositionDialogue(false);
    setPositionResponse(false);
  }

  function PositionSelected(posi) {
    setSelectedPosition(posi);
    setPositionMode("existing");

    console.log(PositionInfo[posi]);

    //WERTE DER POSITION ÜBERNEHMEN
    setPositionName(PositionInfo[posi].name);
    setPositionKommentar(PositionInfo[posi].kommentar);
    setPositionDurchmesser(PositionInfo[posi].durchmesser);

    setPositionOfenmantelverschmutzung(PositionInfo[posi].ofenmantelVerschmutzung);
    setPositionOfenmantelVerformung(PositionInfo[posi].ofenmantelVerformung);
    setPositionAuskleidungsmaterial(PositionInfo[posi].auskleidungsmaterial);
    setPositionAuskleidungsmethode(PositionInfo[posi].auskleidungsmethode);

    setPositionArbeitsfutterHersteller(PositionInfo[posi].arbeitsfutterHersteller);
    setPositionArbeitsfutterProdukt(PositionInfo[posi].arbeitsfutterProdukt);
    setPositionIsolierfutterHersteller(PositionInfo[posi].isolierfutterHersteller);
    setPositionIsolierfutterProdukt(PositionInfo[posi].isolierfutterProdukt);
    setPositionIsolierfutterDicke(PositionInfo[posi].isolierfutterDicke);
    setPositionMoertelArbeitsfutter(PositionInfo[posi].moertelArbeitsfutter);
    setPositionMoertelIsolierfutter(PositionInfo[posi].moertelIsolierfutter);

    setPositionAnkerLieferant(PositionInfo[posi].ankerLieferant);
    setPositionAnkerTyp(PositionInfo[posi].ankerTyp);
    setPositionAnkerQualitaet(PositionInfo[posi].ankerQualitaet);
    setPositionFormatTyp(PositionInfo[posi].formatTyp);

    setPositionFormatEins(PositionInfo[posi].formatEins);
    setPositionFormatZwei(PositionInfo[posi].formatZwei);
    setPositionPassSteinEins(PositionInfo[posi].passSteinEins);
    setPositionPassSteinZwei(PositionInfo[posi].passSteinZwei);
    setPositionFormatEinsRatio(PositionInfo[posi].formatEinsRatio);
    setPositionFormatZweiRatio(PositionInfo[posi].formatZweiRatio);

    setPositionRingeVerband(PositionInfo[posi].ringeVerband);
    setPositionKnirschMoertel(PositionInfo[posi].knirschMoertel);

    setPositionHaltering(PositionInfo[posi].haltering);
    setPositionKonus(PositionInfo[posi].konus);
    setPositionLifterzone(PositionInfo[posi].lifterzone);
    setPositionKettenzone(PositionInfo[posi].kettenzone);
    setPositionKammfutter(PositionInfo[posi].kammfutter);
    setPositionOpferbleche(PositionInfo[posi].opferbleche);
    setPositionDammring(PositionInfo[posi].dammring);
    setPositionSpiralmauerwerk(PositionInfo[posi].spiralmauerwerk);

    setPositionsHistorieStartposition(PositionInfo[posi].historie[PositionInfo[posi].historie.length - 1].startposition);
    setPositionsHistorieLaenge(PositionInfo[posi].historie[PositionInfo[posi].historie.length - 1].laenge);
    setPositionsHistorieStaerke(PositionInfo[posi].historie[PositionInfo[posi].historie.length - 1].staerke);

  }

  console.log(selectedPosition);

  function PositionNew() {
    setSelectedPosition(null);
    ClearFields();
    setPositionMode("new");
  }

  function Save() {
    if (positionMode == "existing") {
      setPositionCommand("update");
      setPositionDialogue(true);
    }
    if (positionMode == "new") {
      setPositionCommand("create");
      setPositionDialogue(true);
    }
  }

  function Delete() {
    setPositionCommand("del");
    setPositionDialogue(true);
  }

  async function UpdatePositionInfo() {
    setSelectedPosition(null);
    setPositionMode("none");
    setPositionLoading(true);
    APILoadPositionDetails(Oefen[selectedOfen].id).then(data => {
      if (data && data.length > 0) {
        setPositionInfo(data);
      }
      else {
        setPositionInfo(null);
      }
      if (data == undefined) {
        setPositionInfo(null);
      }
      setPositionLoading(false);
    });
  }


  function BackToOvens() {
    history("/")
  }

  function ClearFields() {
    setPositionName("");
    setPositionKommentar("");
    setPositionDurchmesser("0");

    setPositionOfenmantelverschmutzung("none");
    setPositionOfenmantelVerformung("none");
    setPositionAuskleidungsmaterial("stein");
    setPositionAuskleidungsmethode("einschicht");

    setPositionArbeitsfutterHersteller("");
    setPositionArbeitsfutterProdukt("");
    setPositionIsolierfutterHersteller("");
    setPositionIsolierfutterProdukt("");
    setPositionIsolierfutterDicke("0");
    setPositionMoertelArbeitsfutter("");
    setPositionMoertelIsolierfutter("");

    setPositionAnkerLieferant("");
    setPositionAnkerTyp("");
    setPositionAnkerQualitaet("");
    setPositionFormatTyp("vdz");

    setPositionFormatEins("");
    setPositionFormatZwei("");
    setPositionPassSteinEins("");
    setPositionPassSteinZwei("");
    setPositionFormatEinsRatio("0");
    setPositionFormatZweiRatio("0");

    setPositionRingeVerband("ringe");
    setPositionKnirschMoertel("knirsch");

    setPositionHaltering(false);
    setPositionKonus(false);
    setPositionLifterzone(false);
    setPositionKettenzone(false);
    setPositionKammfutter(false);
    setPositionOpferbleche(false);
    setPositionDammring(false);
    setPositionSpiralmauerwerk(false);

    setPositionsHistorieStartposition("0");
    setPositionsHistorieLaenge("0");
    setPositionsHistorieStaerke("0");
  }


  const LoadingElement = (PositionLoading == true) ?
    <div className='dialogue'>
      <p>Loading...</p>
    </div>
    : [];
  const DialogueElement = (PositionDialogue == true) ?
    <div className='dialogue'>
      <p>Sind sie sich sicher?</p>
      <table>
        <tbody>
          <tr>
            <td>
              <button onClick={DialogueYes}>Yes</button>
            </td>
            <td>
              <button onClick={DialogueNo}>No</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    : [];

  const PositionenDisplay = (PositionInfo != null) ? PositionInfo.map((posi, i) => {
    return (i == selectedPosition) ?
      <tr style={{ background: '#00FFFF' }} key={i} onClick={() => PositionSelected(i)}><td>{posi.id}</td><td>{posi.name}</td></tr>
      :
      <tr key={i} onClick={() => PositionSelected(i)}><td>{posi.id}</td><td>{posi.name}</td></tr>
      ;

  }) : [];

  return (

    <div>
      {LoadingElement}
      {DialogueElement}
      <div className='tablebox'>
        <table>
          <tbody>
            {PositionenDisplay}
          </tbody>
        </table>
      </div>
      <div>
        <button onClick={BackToOvens}>Zurück Zu Öfen</button>
        <button disabled={positionMode == "new"} onClick={PositionNew}>Neue Position</button>
        <button disabled={positionMode == "none"} onClick={Save}>Speichern</button>
        <button disabled={positionMode != "existing"} onClick={Delete}>Position Löschen</button>
      </div>

      <label>Name</label>
      <input disabled={positionMode != "new"} maxLength="100" value={PositionName} onChange={(e) => setPositionName((e.target.value))} />

      <label>Kommentar</label>
      <textarea disabled={positionMode != "new"} value={PositionKommentar} onChange={(e) => setPositionKommentar((e.target.value))} name="Text1" cols="40" rows="5"></textarea>

      <label>Durchmesser</label>
      <input disabled={positionMode != "new"} value={PositionDurchmesser} onChange={(e) => setPositionDurchmesser((e.target.value))} />

      <fieldset>
        <legend>Allgemein</legend>
        <label>Startposition(m)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={PositionsHistorieStartposition} onChange={(e) => setPositionsHistorieStartposition((e.target.value))} />
        <label>Länge(m)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={PositionsHistorieLaenge} onChange={(e) => setPositionsHistorieLaenge((e.target.value))} />
        <label>Stärke(mm)</label>
        <input disabled={positionMode == "none"} maxLength="100" value={PositionsHistorieStaerke} onChange={(e) => setPositionsHistorieStaerke((e.target.value))} />
      </fieldset>

      <fieldset>
        <legend>Ofenmantelverschmutzung</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_none" name="Ofenmantelverschmutzung" value="none" checked={PositionOfenmantelverschmutzung == "none"} onChange={() => setPositionOfenmantelverschmutzung("none")} />
          <label htmlFor="Ofenmantelverschmutzung_none">Nicht verschmutzt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_light" name="Ofenmantelverschmutzung" value="light" checked={PositionOfenmantelverschmutzung == "light"} onChange={() => setPositionOfenmantelverschmutzung("light")} />
          <label htmlFor="Ofenmantelverschmutzung_light">Leicht verschmutzt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Ofenmantelverschmutzung_strong" name="Ofenmantelverschmutzung" value="strong" checked={PositionOfenmantelverschmutzung == "strong"} onChange={() => setPositionOfenmantelverschmutzung("strong")} />
          <label htmlFor="Ofenmantelverschmutzung_strong">Stark verschmutzt</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Ofenmantelverformung</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_none" name="OfenmantelVerformung" value="none" checked={PositionOfenmantelVerformung == "none"} onChange={() => setPositionOfenmantelVerformung("none")} />
          <label htmlFor="OfenmantelVerformung_none">Nicht verformt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_light" name="OfenmantelVerformung" value="light" checked={PositionOfenmantelVerformung == "light"} onChange={() => setPositionOfenmantelVerformung("light")} />
          <label htmlFor="OfenmantelVerformung_light">Leicht verformt</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="OfenmantelVerformung_strong" name="OfenmantelVerformung" value="strong" checked={PositionOfenmantelVerformung == "strong"} onChange={() => setPositionOfenmantelVerformung("strong")} />
          <label htmlFor="OfenmantelVerformung_strong">Stark verformt</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Auskleidungsmaterial</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Auskleidungsmaterial_stein" name="Auskleidungsmaterial" value="stein" checked={PositionAuskleidungsmaterial == "stein"} onChange={() => { setPositionAuskleidungsmaterial("stein"); setPositionAuskleidungsmethode("einschicht"); }} />
          <label htmlFor="Auskleidungsmaterial_stein">Steine</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Auskleidungsmaterial_masse" name="Auskleidungsmaterial" value="masse" checked={PositionAuskleidungsmaterial == "masse"} onChange={() => { setPositionAuskleidungsmaterial("masse"); setPositionAuskleidungsmethode("giessen_vibrieren"); }} />
          <label htmlFor="Auskleidungsmaterial_masse">Masse</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Auskleidungsmethode</legend>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "masse"} type="radio" id="Auskleidungsmethode_einschicht" name="Auskleidungsmethode" value="einschicht" checked={PositionAuskleidungsmethode == "einschicht"} onChange={() => setPositionAuskleidungsmethode("einschicht")} />
          <label hidden={PositionAuskleidungsmaterial == "masse"} htmlFor="Auskleidungsmethode_einschicht">Einschichtfutter</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "masse"} type="radio" id="Auskleidungsmethode_zweischicht" name="Auskleidungsmethode" value="zweischicht" checked={PositionAuskleidungsmethode == "zweischicht"} onChange={() => setPositionAuskleidungsmethode("zweischicht")} />
          <label hidden={PositionAuskleidungsmaterial == "masse"} htmlFor="Auskleidungsmethode_zweischicht">Zweischichtfutter</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_giessen_vibrieren" name="Auskleidungsmethode" value="giessen_vibrieren" checked={PositionAuskleidungsmethode == "giessen_vibrieren"} onChange={() => setPositionAuskleidungsmethode("giessen_vibrieren")} />
          <label hidden={PositionAuskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_giessen_vibrieren">Gießen/Vibrieren</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_trockenspritzen" name="Auskleidungsmethode" value="trockenspritzen" checked={PositionAuskleidungsmethode == "trockenspritzen"} onChange={() => setPositionAuskleidungsmethode("trockenspritzen")} />
          <label hidden={PositionAuskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_trockenspritzen">Trockenspritzen</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_nassspritzen" name="Auskleidungsmethode" value="nassspritzen" checked={PositionAuskleidungsmethode == "nassspritzen"} onChange={() => setPositionAuskleidungsmethode("nassspritzen")} />
          <label hidden={PositionAuskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_nassspritzen">Nassspritzen</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} hidden={PositionAuskleidungsmaterial == "stein"} type="radio" id="Auskleidungsmethode_blockline" name="Auskleidungsmethode" value="blockline" checked={PositionAuskleidungsmethode == "blockline"} onChange={() => setPositionAuskleidungsmethode("blockline")} />
          <label hidden={PositionAuskleidungsmaterial == "stein"} htmlFor="Auskleidungsmethode_blockline">Refra-Blockline</label>
        </div>
      </fieldset>

      <fieldset hidden={PositionAuskleidungsmaterial == "masse"} >
        <legend>Materialinformationen</legend>
        <label>Arbeitsfutter Hersteller</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionArbeitsfutterHersteller} onChange={(e) => setPositionArbeitsfutterHersteller((e.target.value))} />
        <label>Arbeitsfutter Produkt</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionArbeitsfutterProdukt} onChange={(e) => setPositionArbeitsfutterProdukt((e.target.value))} />
        <label hidden={PositionAuskleidungsmethode == "einschicht"}>Isolierfutter Hersteller</label>
        <input hidden={PositionAuskleidungsmethode == "einschicht"} disabled={positionMode != "new"} maxLength="100" value={PositionIsolierfutterHersteller} onChange={(e) => setPositionIsolierfutterHersteller((e.target.value))} />
        <label hidden={PositionAuskleidungsmethode == "einschicht"}>Isolierfutter Produkt</label>
        <input hidden={PositionAuskleidungsmethode == "einschicht"} disabled={positionMode != "new"} maxLength="100" value={PositionIsolierfutterProdukt} onChange={(e) => setPositionIsolierfutterProdukt((e.target.value))} />
        <label hidden={PositionAuskleidungsmethode == "einschicht"}>Isolierfutter Dicke</label>
        <input hidden={PositionAuskleidungsmethode == "einschicht"} disabled={positionMode != "new"} value={PositionIsolierfutterDicke} onChange={(e) => setPositionIsolierfutterDicke((e.target.value))} />
        <label hidden={PositionKnirschMoertel == "knirsch"} >Mörtel Arbeitsfutter</label>
        <input hidden={PositionKnirschMoertel == "knirsch"} disabled={positionMode != "new"} maxLength="100" value={PositionMoertelArbeitsfutter} onChange={(e) => setPositionMoertelArbeitsfutter((e.target.value))} />
        <label hidden={PositionAuskleidungsmethode == "einschicht" || PositionKnirschMoertel == "knirsch"}>Mörtel Isolierfutter</label>
        <input hidden={PositionAuskleidungsmethode == "einschicht" || PositionKnirschMoertel == "knirsch"} disabled={positionMode != "new"} maxLength="100" value={PositionMoertelIsolierfutter} onChange={(e) => setPositionMoertelIsolierfutter((e.target.value))} />
      </fieldset>

      <fieldset hidden={PositionAuskleidungsmaterial == "stein"} >
        <legend>Materialinformationen</legend>
        <label>Arbeitsfutter Hersteller</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionArbeitsfutterHersteller} onChange={(e) => setPositionArbeitsfutterHersteller((e.target.value))} />
        <label>Arbeitsfutter Produkt</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionArbeitsfutterProdukt} onChange={(e) => setPositionArbeitsfutterProdukt((e.target.value))} />
        <label>Ankerlieferant</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionAnkerLieferant} onChange={(e) => setPositionAnkerLieferant((e.target.value))} />
        <label>Ankertyp</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionAnkerTyp} onChange={(e) => setPositionAnkerTyp((e.target.value))} />
        <label>Ankerqualität</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionAnkerQualitaet} onChange={(e) => setPositionAnkerQualitaet((e.target.value))} />
      </fieldset>

      <fieldset hidden={PositionAuskleidungsmaterial == "masse"} >
        <legend>Formattyp</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_vdz" name="Formattyp" value="vdz" checked={PositionFormatTyp == "vdz"} onChange={() => setPositionFormatTyp("vdz")} />
          <label htmlFor="Formattyp_vdz">VDZ Formate</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_iso" name="Formattyp" value="iso" checked={PositionFormatTyp == "iso"} onChange={() => setPositionFormatTyp("iso")} />
          <label htmlFor="Formattyp_iso">ISO Formate</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Formattyp_custom" name="Formattyp" value="custom" checked={PositionFormatTyp == "custom"} onChange={() => setPositionFormatTyp("custom")} />
          <label htmlFor="Formattyp_custom">Sonderformate</label>
        </div>
      </fieldset>

      <fieldset hidden={PositionAuskleidungsmaterial == "masse"} >
        <legend>Formate</legend>
        <label>Format 1</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionFormatEins} onChange={(e) => setPositionFormatEins((e.target.value))} />
        <label>Format 2</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionFormatZwei} onChange={(e) => setPositionFormatZwei((e.target.value))} />
        <label>Passstein 1</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionPassSteinEins} onChange={(e) => setPositionPassSteinEins((e.target.value))} />
        <label>Passstein 2</label>
        <input disabled={positionMode != "new"} maxLength="100" value={PositionPassSteinZwei} onChange={(e) => setPositionPassSteinZwei((e.target.value))} />

        <label>Verhältnis Format 1</label>
        <input disabled={positionMode != "new"} value={PositionFormatEinsRatio} onChange={(e) => setPositionFormatEinsRatio((e.target.value))} />
        <label>Verhältnis Format 2</label>
        <input disabled={positionMode != "new"} value={PositionFormatZweiRatio} onChange={(e) => setPositionFormatZweiRatio((e.target.value))} />
      </fieldset>
      <fieldset hidden={PositionAuskleidungsmaterial == "masse"}>
        <legend>Vermauerungsmethode</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Vermauerungsmethode_ringe" name="Vermauerungsmethode" value="ringe" checked={PositionRingeVerband == "ringe"} onChange={() => setPositionRingeVerband("ringe")} />
          <label htmlFor="OfenmantelVerformung_none">Ringe</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Vermauerungsmethode_verband" name="Vermauerungsmethode" value="verband" checked={PositionRingeVerband == "verband"} onChange={() => setPositionRingeVerband("verband")} />
          <label htmlFor="OfenmantelVerformung_light">Verband</label>
        </div>
      </fieldset>

      <fieldset hidden={PositionAuskleidungsmaterial == "masse"}>
        <legend>Mörtel</legend>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Moertel_knirsch" name="Moertel" value="knirsch" checked={PositionKnirschMoertel == "knirsch"} onChange={() => setPositionKnirschMoertel("knirsch")} />
          <label htmlFor="OfenmantelVerformung_none">knirsch</label>
        </div>
        <div>
          <input disabled={positionMode != "new"} type="radio" id="Moertel_moertel" name="Moertel" value="moertel" checked={PositionKnirschMoertel == "moertel"} onChange={() => setPositionKnirschMoertel("moertel")} />
          <label htmlFor="OfenmantelVerformung_light">mit Mörtel</label>
        </div>
      </fieldset>

      <fieldset>
        <legend>Anmerkungen</legend>
        <input disabled={positionMode != "new"} checked={PositionHaltering} type="checkbox" id="PositionHaltering" onChange={(e) => setPositionHaltering((e.target.checked))} />
        <label htmlFor="PositionHaltering">Haltering</label>
        <input disabled={positionMode != "new"} checked={PositionKonus} type="checkbox" id="PositionKonus" onChange={(e) => setPositionKonus((e.target.checked))} />
        <label htmlFor="PositionKonus">Konus</label>
        <input disabled={positionMode != "new"} checked={PositionLifterzone} type="checkbox" id="PositionLifterzone" onChange={(e) => setPositionLifterzone((e.target.checked))} />
        <label htmlFor="PositionLifterzone">Lifterzone</label>
        <input disabled={positionMode != "new"} checked={PositionKettenzone} type="checkbox" id="PositionKettenzone" onChange={(e) => setPositionKettenzone((e.target.checked))} />
        <label htmlFor="PositionKettenzone">Kettenzone</label>
        <input disabled={positionMode != "new"} checked={PositionKammfutter} type="checkbox" id="PositionKammfutter" onChange={(e) => setPositionKammfutter((e.target.checked))} />
        <label htmlFor="PositionKammfutter">Kammfutter</label>
        <input disabled={positionMode != "new"} checked={PositionOpferbleche} type="checkbox" id="PositionOpferbleche" onChange={(e) => setPositionOpferbleche((e.target.checked))} />
        <label htmlFor="PositionOpferbleche">Opferbleche</label>
        <input disabled={positionMode != "new"} checked={PositionDammring} type="checkbox" id="PositionDammring" onChange={(e) => setPositionDammring((e.target.checked))} />
        <label htmlFor="PositionDammring">Dammring</label>
        <input disabled={positionMode != "new"} checked={PositionSpiralmauerwerk} type="checkbox" id="PositionSpiralmauerwerk" onChange={(e) => setPositionSpiralmauerwerk((e.target.checked))} />
        <label htmlFor="PositionSpiralmauerwerk">Spiralmauerwerk</label>
      </fieldset>
    </div>
  );
}

export default Positions;