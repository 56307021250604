import logo from './logo.svg';
import './App.css';
import Positions from './pages/Positions';
import Ovens from './pages/Ovens';
import History from './pages/History'
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Ovens />} />
        <Route path="/" element={<Ovens />} />
        <Route path="/positions" element={<Positions />} />
        <Route path="/history" element={<History />} />
    </Routes>
    </BrowserRouter >
  );
}



export default App;
