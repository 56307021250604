import logo from '../logo.svg';
import React from "react";
import { useNavigate } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams } from "react-router-dom";

import './History.css'

import { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react";
import { APIGetOvens } from '../api/APIGetOvens';
import { APIChangeOven } from '../api/APIChangeOven';
import { APIDeleteOven } from '../api/APIDeleteOven';


import useLiningChartsState from '../state/liningcharts';

function History() {
    const history = useNavigate();



    return (
        <div>
            historie
        </div>
    );
}

export default History;